import SpecialtyDishService from "../api/specialty-dish-service";
import DataService from "../api/basic-data-service";
import OrderService from "../api/order-service";
import User from "../api/user";
import Member from "../api/member";
import Location from "../api/location"
import { useRoute } from "vue-router";
import { buildOptions, getOption, getOptionValue } from "../utils";
import { build } from "../utils/pageable";
import { Dialog } from 'vant';
import { userLoginTimeOutInit } from "../api/user-timeout";
import SystemConfigService from "../api/system-config";
const REC_MODE = 21 , FILTER_MODE = 22 ,  SEARCH_MODE = 23;

const retrieveSpecialtyDishs = (state, mode) => build(mode)
    .next((key, number, size) => retrieveNextSpecialtyDishs(state, key, number, size))
    .then(results => state.dishs = results)
const retrieveNextSpecialtyDishs = (state, key, number, size) => {
    if (key == REC_MODE) {
        return SpecialtyDishService.getRecommendSpecialtyDishs(number, size);
    } else if (key == FILTER_MODE || key == SEARCH_MODE) {
        return SpecialtyDishService.searchSpecialtyDishs(state.keyword, state.cityId, state.styleId, state.priceId, number, size)
    }
}
const pullRefresh = state => retrieveSpecialtyDishs(state);
const filter = state => retrieveSpecialtyDishs(state , FILTER_MODE);
const recommend = state => retrieveSpecialtyDishs(state, REC_MODE);
const search = state => retrieveSpecialtyDishs(state , state.keyword ? SEARCH_MODE : REC_MODE);
const saleRate = dish => {
    let value = parseInt((dish.total - dish.balance)/ dish.total * 100) ;
    if( isNaN(value)) {
      return 0;
    }
    return value <= 0 ? 0 : value;
};
const onSale = (dish) => {
    if( dish.expirationTimeSeconds <= 0) {
      return false;
    }
    let rate = saleRate(dish);
    return rate >= 0 && rate <= 100;
}
const buying = dish => OrderService.addOrder(dish.activityId)
const onLoad = state => {
    const level = "t2"
    // state.loading = false;
    recommend(state);
    DataService.getCities(level).then(results => {
        state.cities = buildOptions(results, "城市", 0, c => ({ value: c.id, text: c.name }));
        state.loading = false;
        return Location.getLocation();
    }).then(result => {
        if( result.first) {
            Dialog.confirm({ message: '是否允许访问您的位置。' })
                .then(() => {
                    Location.setAllowLocation(true);
                    state.cityId  = getOptionValue(state.cities , result.city)
                    filter(state);
                }).catch(() => {
                    Location.setAllowLocation(false);
                });
        } else {
            if( Location.getAllowLocation() == 'true') {
                state.cityId  = getOptionValue(state.cities , result.city)
                filter(state);
            }
        }
    });
    DataService.getCookingStyles(level).then(results => {
        state.cookingStyles = buildOptions(results, "菜系", 0, c => ({ value: c.id, text: c.name }));
        state.loading = false;
    })
    DataService.getPrices(level).then(results => {
        state.prices = buildOptions(results, "人均", 0, c => {
            let opt = {
                value: c.id,
                text: c.min + "-" + c.max
            };
            if(!c.max) {
                opt.text = ">" + c.min;
            }
            return opt;
        });
        state.loading = false;
    });
}
const onEpidemic = state => {
    SystemConfigService.isEnableEpidemic().then(result => {
        state.enableEpidemic = (result.msg || 'false' ) == 'true';
        if( state.enableEpidemic ) {
            return SystemConfigService.getEpidemicDishContent();
        }
    }).then(result => state.epidemicContent = state.epidemicContent = (result ? result.msg : "") || "")
}
const onMounted = (state, router) => {
    const { code, client, cardProductName, cardLevel, cardBin, cardId, userId, jumpUrl } = useRoute().query;
    const data = { code, client, cardProductName, cardLevel, cardBin, cardId, userId, jumpUrl };
    User.login(data)
        .then(() => Member.getMemberInfo(), () => User.notFound(router))
        .then((info) => {
            state.equityCount = info.equityCount -  info.useEquityCount;
            state.award = info.award;
            onLoad(state);
            onEpidemic(state);
            userLoginTimeOutInit(data);
        }, () => User.notFound(router));
}

export default {
    search,
    filter,
    pullRefresh,
    saleRate,
    onSale,
    buying,
    getOption,
    onMounted
}