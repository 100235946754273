import { get, post } from "../utils/http"

function getRecommendSpecialtyDishs(num, limit) {
    const url = "/v1/specialty-dish/recommend";
    let data = {
        num,
        limit
    };
    return post({ url , data });
}

function getSpecialtyDishDetails(dishId) {
    const url = "/v1/specialty-dish/details/" + dishId;
    return get({ url });
}

function searchSpecialtyDishs(keyword, cityId, styleId, priceId, num, limit) {
    const url = "/v1/specialty-dish/s";
    let data = {
        keyword,
        cityId,
        styleId,
        priceId,
        num,
        limit
    }
    return post({ url, data });
}

export default {
    getRecommendSpecialtyDishs,
    getSpecialtyDishDetails,
    searchSpecialtyDishs
}