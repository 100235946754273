let page = null;

export const build = ( key ) => {
    if( !key ) {
        if( !page ) {
            return new Pageable(-1)
        }
        return page;
    } else  {
        page = new Pageable(key);
        return page;
    }
}

export class Pageable {
    constructor(key, size) {
        this.key = key;
        this.number = 0;
        this.size = size || 5;
        this.results = [];
        this.more = true;
    }
    add(values) {
        for (let i = 0; i < values.length; i++) {
            if(!this.hasResult(values[i])) {
                this.results.push(values[i]);
            }
        }
    }
    hasResult(v) {
        for (let i = 0; i < this.results.length; i++) {
            if(v.id === this.results[i].id) {
                return true;
            }
        }
        return false;
    }
    isKey(k) {
        return this.key == k;
    }
    reset() {
        this.number = 1;
        this.results = [];
        this.more = true;
    }
    next(call) {
        this.number += 1;
        if (call != null) {
            return new Promise((resolve, reject) => {
                if (!this.more) {
                    resolve(this.results);
                    return;
                }
                let primise = call.apply(this, [this.key, this.number, this.size]);
                if( primise && primise.then ) {
                    primise.then(results => {
                        if (results.length == 0) {
                            this.more = false;
                        }
                        this.add(results);
                        resolve(this.results);
                    }, reject)
                } else {
                    resolve(this.results);
                }
            });
        } else {
            return new Promise((resolve) => {
                resolve(this.results);
            });
        }
    }
}

