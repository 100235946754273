import { originGet } from "../utils/http"

function getConfig(configKey) {
  const url = '/system/config/configKey/' + configKey;
  return originGet({ url});
}

function isEnableEpidemic() {
  return getConfig("ec.epidemic.enable")
}

function getEpidemicRestContent() {
  return getConfig("ec.epidemic.t2-rest")
}

function getEpidemicDishContent() {
  return getConfig("ec.epidemic.dish")
}

export default {
  isEnableEpidemic,
  getConfig,
  getEpidemicRestContent,
  getEpidemicDishContent
}
