const BAIDU_AK = process.env.VUE_APP_BAIDU_AK;
const ENABLE_MOCK = process.env.VUE_APP_LOCATION_ENABLE_MOCK || false;
const result = {
    "address": "CN|\u6c5f\u82cf\u7701|\u82cf\u5dde\u5e02|None|None|95|95",
    "content": {
        "address_detail": {
            "province": "\u6c5f\u82cf\u7701", "city": "\u82cf\u5dde\u5e02",
            "district": "", "street_number": "", "adcode": "320583", "street": "", "city_code": 224
        },
        "point": { "y": "3649831.15", "x": "13424431.99" },
        "address": "\u6c5f\u82cf\u7701\u82cf\u5dde\u5e02"
    }, "status": 0
}
const LOCAL_CITY_KEY = "ec_city";
const LOCAL_ALLOW_LOCATION_KEY = "ec_allow_loc";

function getCity() {
    return sessionStorage.getItem(LOCAL_CITY_KEY);
}
function setCity(cityName) {
    sessionStorage.setItem(LOCAL_CITY_KEY, cityName);
}
function getAllowLocation() {
    return sessionStorage.getItem(LOCAL_ALLOW_LOCATION_KEY);
}
function setAllowLocation(enable) {
    sessionStorage.setItem(LOCAL_ALLOW_LOCATION_KEY, enable);
}
function getLocation() {
    const url = "https://api.map.baidu.com/location/ip?ak=" + BAIDU_AK;
    return new Promise((resolve, reject) => {
        if (ENABLE_MOCK === 'true') {
            resolve(getLocalCity(result))
        } else {
            jsonp(url).then(ret => {
                try {
                    resolve(getLocalCity(ret))
                } catch (error) {
                    reject({ errorCode: 404 })
                }
            }, () => reject({ errorCode: 404 }))
        }
    })
}
function getLocalCity(r) {
    let cityName = r.content.address_detail.city;
    cityName = cityName.substr(0, cityName.length - 1);
    let sesCityName = getCity();
    setCity(cityName);
    return {
        city: cityName,
        first: sesCityName != cityName
    };
}
function jsonp(url) {
    return new Promise((resolve, reject) => {
        if (!url) {
            reject({
                errorCode: 401,
                message: "请传入一个url参数"
            })
            return;
        }
        window.jsonpCallBack = (result) => resolve(result);
        var JSONP = document.createElement("script");
        JSONP.type = "text/javascript";
        JSONP.src = `${url}&callback=jsonpCallBack`;
        document.getElementsByTagName("head")[0].appendChild(JSONP);
        setTimeout(() => {
            document.getElementsByTagName("head")[0].removeChild(JSONP)
        }, 500)
    })
}

export default {
    getLocation,
    getAllowLocation,
    setAllowLocation
}