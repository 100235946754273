<template>
  <div class="index specialy-details">
    <!-- <van-nav-bar :title="dish.name" left-arrow @click-left="onBack" /> -->
    <!-- <van-image width="100%" height="180px" fit="cover" :src="dish.imageUrl" /> -->
    <van-swipe :autoplay="4000">
      <van-swipe-item v-for="imgUrl in images" :key="imgUrl">
        <van-image
          width="100%"
          height="180px"
          fit="cover"
          :src="resetImageUrl(imgUrl,0,0)"
          lazy-load
        />
      </van-swipe-item>
    </van-swipe>

    <van-skeleton title :row="4" :loading="loading">
      <div class="specialy-detail ho-center">
        <div class="ho-center specialy-info">
            <span class="currency">¥</span>
            <span class="price">{{ dish.price }}</span>
            <span class="ori-price">原价:¥{{ dish.originPrice }}</span>
        </div>
        <div class="ho-center">
          <div class="text">已售</div>
          <van-circle
            v-model:current-rate="currentRate"
            :rate="saleRate()"
            :size="29"
            :layer-color="'#ebedf0'"
            :color="'#2E6DC8'"
            :text="saleRate() + '%'"
          />
        </div>
      </div>
      <div class="specialy-intro">
        {{dish.intro}}
      </div>
      <!-- <van-collapse v-model="activeNames" :border="false">
        <van-collapse-item :title="'招牌菜' + dish.name + '介绍'" name="1">
        </van-collapse-item>
      </van-collapse> -->
    </van-skeleton>

    <van-notice-bar color="#999999" background="#F4F9FF" left-icon="arrow-down">
      如果您还没有预订餐厅，可点击左下角预订餐厅
    </van-notice-bar>

    <div class="resturant-action">
      <van-icon name="shop-o" @click="onRestaurant" />
      <van-button class="btn-order" v-if="isOnSale()" @click="onBuying"> 购买招牌菜 </van-button>
      <van-button class="btn-order" disabled v-else> 购买招牌菜 </van-button>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Toast } from "vant";
import { loading , clearLoading} from "../utils/loading";
import { resetImageUrl } from "../utils";
import SpecialtyDishService from "../api/specialty-dish-service";
import OrderService from "../api/order-service";
import Controller from "./specialty-dish-controller";
import "../css/restaurant-details.scss";
import "../css/specialty-dish-details.scss";

export default {
  setup() {
    const state = reactive({
      dish: {},
      currentRate: 0,
      activeNames: ["1"],
      loading: true,
      images: []
    });
    const router = useRouter();
    const { id } = useRoute().params;
    const onRestaurant = () => router.push("/restaurant/" + state.dish.restaurantId);
    // const onBack = () => router.back();
    const saleRate = () => Controller.saleRate(state.dish);
    const isOnSale = () => Controller.onSale(state.dish);
    const onBuying = () => {
      loading()
      OrderService.addOrder(state.dish.activityId).then((orderId) => {
          router.push("/order/" + orderId + "/paying") 
          clearLoading()
        }, (err) => {
          clearLoading()
          Toast.fail(err.message)
        }
      );
    };
    onMounted(() => {
      loading()
      SpecialtyDishService.getSpecialtyDishDetails(id).then((r) => {
        state.dish = r;
        state.loading = false;
        state.images = [r.imageUrl];
        r.images.forEach(url => state.images.push(url))
        clearLoading()
      }, clearLoading)
    });
    return {
      ...toRefs(state),
      onRestaurant,
      // onBack,
      onBuying,
      saleRate,
      isOnSale,
      resetImageUrl
    };
  },
};
</script>
