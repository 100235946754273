import { get } from "../utils/http"

function getCities(level) {
    const url = "/v1/cities";
    let data = { level };
    return get({ url , data });
}

function getCookingStyles(level) {
    const url = "/v1/cooking-styles";
    let data = { level };
    return get({ url , data});
}

function getTastes() {
    const url = "/v1/tastes";
    return get({ url });
}

function getQuestions() {
    const url = "/v1/questions";
    return get({ url });
}

function getNotifications() {
    const url = "/v1/notifications";
    return get({ url });
}

function getPrices(level) {
    const url = "/v1/prices";
    let data = { level };
    return get({ url , data });
}

export default {
    getCities,
    getTastes,
    getCookingStyles,
    getQuestions,
    getNotifications,
    getPrices
}